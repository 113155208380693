export default class IFrameFactory {
	public static async CreateIframe(documentUrl: string): Promise<IIAddinIframe> {
		try {
			if (documentUrl.indexOf('webdav') > -1) {
				let IFrameWebDav = await import(/* webpackChunkName: "IFrameWebDav" */ './IframeWebDav');
				return new IFrameWebDav.default(documentUrl);
			} else {
				let IFrameOfficeOnline = await import(/* webpackChunkName: "IframeOfficeOnline" */ './IframeOfficeOnline');
				return new IFrameOfficeOnline.default();
			}
		} catch (e) {
			console.error(e.message);
		}
	}
}