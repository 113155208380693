export default class AddinFactory {
	public static async CreateAddin(): Promise<IOfficeAddin> {
		try {
			if (Office.context.host === Office.HostType.Word) {
				if (Office.context.platform === Office.PlatformType.OfficeOnline) {
					let AddinOfficeOnlineWord = await import(/* webpackChunkName: "AddinOfficeOnlineWord" */ './AddinOfficeOnlineWord');
					return new AddinOfficeOnlineWord.default();
				} else {
					let AddinWebDavWord = await import(/* webpackChunkName: "AddinWebDavWord" */ './AddinWebDavWord');
					return new AddinWebDavWord.default();
				}
			} else if (Office.context.host === Office.HostType.Excel) {
				let AddinExcel = await import(/* webpackChunkName: "AddinExcel" */ './AddinExcel');
				return new AddinExcel.default();
			}
		} catch (e) {
			console.error(e.message);
		}
	}
}