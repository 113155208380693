import AddinFactory from './AddinFactory';
import IFrameFactory from './IFrameFactory';

export default class TaskPane {
	private m_addin: IOfficeAddin;
	private m_addinIframe: IIAddinIframe;

	constructor(
	) {
	}

	public async Initialize() {
		try {
			this.m_addinIframe = await IFrameFactory.CreateIframe(Office.context.document.url);
			this.m_addin = await AddinFactory.CreateAddin();
			await this.m_addinIframe.Authenticate();
			let mainFrame = document.getElementById('mainIFrame') as HTMLFrameElement;
			mainFrame.onload = () => document.getElementById("loading-panel").style.display = "none";
			this.m_addinIframe.LoadFramePage();

			window.addEventListener('message', this.ReceiveJSApiMessage, false);
		} catch (e) {
			console.error(e.message);
		}
	}

	private ReceiveJSApiMessage = async (event: MessageEvent) => {
		let eventData = event.data as JSApiEventData;
		let result = null;
		try {
			if (eventData.messageID == 'insertField') {
				await this.m_addin.InsertField(eventData.payload)
			} else if (eventData.messageID == 'insertLink') {
				this.m_addin.InsertLink(eventData.payload)
			} else if (eventData.messageID == 'openDialog') {
				result = await this.m_addin.OpenDialog(eventData.payload)
			} else if (eventData.messageID == 'insertLogo') {
				this.m_addin.InsertLogo(eventData.payload)
			}

			// Return the response of the operation
			this.SendPTMessage(event, 'officeCallback', result);
		} catch (e) {
			console.error(e);
		}
	}

	private SendPTMessage(event: MessageEvent, message: string, payload: any) {
		(event.source as Window).postMessage({
			callbackID: (event.data as JSApiEventData).callbackID,
			messageID: message,
			payload: payload,
		} as JSApiEventData, event.origin);
	}
}