import ready from 'document-ready-promise';
import TaskPane from './TaskPane';


if (navigator.userAgent.indexOf("Trident") !== -1) {
    /*
       Trident is the webview in use. Do one of the following:
        1. Provide an alternate add-in experience that doesn't use any of the HTML5
           features that aren't supported in Trident (Internet Explorer 11).
        2. Enable the add-in to gracefully fail by adding a message to the UI that
           says something similar to:
           "This add-in won't run in your version of Office. Please upgrade either to
           perpetual Office 2021 (or later) or to a Microsoft 365 account."
    */
    ready().then(async () => {
        let iframe = document.getElementById('mainIFrame') as HTMLIFrameElement;
        iframe.onload = () => document.getElementById("loading-panel").style.display = "none";
        iframe.src = 'markunsupported.html';
    });
    //hide loading panel
} else {
    /* 
       A webview other than Trident or EdgeHTML is in use.
       Provide a full-featured version of the add-in here.
    */
    Office.initialize = () => {
        try {
            ready().then(async () => {
                let taskPane = new TaskPane();
                taskPane.Initialize();
            });
        } catch (e) {
            console.error(e.message);
        }
    };
}



